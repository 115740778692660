@use "../../../styles/variables";
@use "../../../styles/textStyles";
@use "../../../styles/specifics";

.tbBubble {
  overflow: hidden;
  border-radius: variables.$border-radius2;
  padding: variables.$base-factor * 3 variables.$base-factor * 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  & > * {
    flex: 1;
  }

  &Header {
    @include textStyles.tag;

    display: flex;
    align-items: center;

    svg {
      margin-right: variables.$base-factor * 2;
      width: variables.$base-factor * 4;
      stroke-width: 1.5px;

      * {
        stroke-width: 1.5px;
      }
    }
  }

  &Body {
    @include textStyles.label;

    margin-top: variables.$base-factor * 2;
    text-align: left;
  }

  &Fixed {
    display: inline-flex;
    max-width: 800px;

    & > * {
      flex: unset;
    }
  }

  &Fill {
    width: 100%;
    box-sizing: border-box;
  }

  &Info {
    background-color: variables.$white101;
    color: variables.$black80;

    svg {
      @include specifics.colorIcons($color: variables.$black80);
    }

    hr {
      border-color: variables.$black80;
    }
  }

  &Danger {
    background-color: variables.$error;
    color: variables.$white90;

    svg {
      @include specifics.colorIcons($color: variables.$white90);
    }

    hr {
      border-color: variables.$white90;
    }
  }

  &Validation {
    background-color: variables.$valid;
    color: variables.$white90;

    svg {
      @include specifics.colorIcons($color: variables.$white90);
    }

    hr {
      border-color: variables.$white90;
    }
  }
}
