@use "../../../../styles/variables";
@use "../../../../styles/textStyles";
@use "../../../../styles/specifics";
@use "../../../../styles/utils";
@use "../../../../styles/animations";

.tbInputSelect {
  align-items: center;
  border-radius: variables.$border-radius2;
  display: flex;
  justify-content: center;
  min-width: variables.$min-input-width;

  &:not(.tbInputSelectHasError) > .tbInputSelectControl:not(.tbInputSelectControlDisabled):focus-within {
    border-color: variables.$purple100;
  }

  .tbInputSelectControl {
    @include utils.allUnset;
    @include textStyles.body;

    align-items: center;
    background-color: variables.$white90;
    border: 1px solid variables.$white107;
    border-radius: variables.$border-radius2;
    box-sizing: border-box;
    color: variables.$black100;
    cursor: pointer;
    display: flex;
    height: variables.$input-height;
    justify-content: center;
    padding: 0;
    padding-left: variables.$base-factor * 3;
    width: 100%;
    transition: border 0.35s ease-out;

    &IconWrapper {
      display: inline-flex;

      svg {
        @include specifics.colorIcons(variables.$black50, variables.$white90);

        display: inline-block;
        margin-right: variables.$base-factor * 2;
        width: variables.$base-factor * 4;
        stroke-width: 1.5px;
        transition: all 0.35s ease-out;

        path {
          transition: all 0.35s ease-out;
        }
      }

      &Separator {
        background-color: variables.$white107;
        display: inline-block;
        height: variables.$input-height * 0.7;
        margin-right: variables.$base-factor * 2;
        width: 1px;
        transition: all 0.35s ease-out;
      }
    }

    &Disabled {
      cursor: not-allowed;
      opacity: 0.7;
      pointer-events: auto;
    }

    &:hover {
      border-color: variables.$white107;
    }
  }

  .tbInputSelectPlaceholder {
    color: variables.$black50;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .tbInputSelectValueContainerSingle {
    display: flex;
    align-items: center;
    padding: 0;

    svg {
      margin-right: variables.$base-factor;
      width: variables.$base-factor * 4;

      @include specifics.colorIcons(variables.$black100, variables.$white90);
    }

    .tbInputSelectInput {
      color: variables.$black100;
    }
  }

  .tbInputSelectValueContainerMulti {
    padding: 0;
    max-height: 100%;
    overflow: hidden;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    .tbInputSelectInput {
      color: variables.$black100;

      &Hidden {
        position: absolute;
        top: -100px;
      }
    }

    :global {
      [class$="multiValue"] {
        display: none !important;
      }
    }
  }

  .tbInputSelectMenu {
    border: 1px solid variables.$white107;
    align-items: center;
    background-color: none;
    border-radius: variables.$border-radius2;
    color: variables.$black100;
    display: flex;
    flex-direction: column;
    margin-top: variables.$base-factor;
    box-shadow: 0 2px 7px rgba(155, 154, 159, 16%);

    & > div {
      @include animations.animate(fadeInUp, 0.15s);

      border-radius: variables.$border-radius2;
      width: 100%;
      box-shadow: variables.$box-shadow1;

      svg {
        @include specifics.autoStrokeWidthIcons;
      }
    }
  }

  .tbInputSelectOption {
    @include textStyles.label;

    align-items: center;
    background-color: transparent;
    cursor: pointer;
    display: inline-flex;
    justify-content: flex-start;

    svg {
      padding-right: variables.$base-factor * 3;
      transition: all 0.35s ease-out;
      width: variables.$base-factor * 4;

      @include specifics.autoColorChainIcon;
      @include specifics.colorIcons(variables.$black100, variables.$white90);
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:target {
      background-color: variables.$white100;
    }

    &.tbInputSelectOptionSelected {
      background-color: variables.$white104;
      color: variables.$black100;
    }
  }

  [class$="-multiValue"] {
    background-color: unset;
  }

  .tbInputSelectIndicatorSeparator {
    background-color: variables.$white107;
    border-radius: variables.$border-radius3;
    height: 70%;
    width: 1px;
    transition: all 0.35s ease-out;
  }

  [class$="-indicatorContainer"] {
    height: 100%;
    padding: 0;
    transition: background 0.15s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: variables.$white102;
    }
  }

  .tbInputSelectIndicatorsContainer {
    border-top-right-radius: variables.$border-radius2;
    border-bottom-right-radius: variables.$border-radius2;
    overflow: hidden;

    svg {
      @include specifics.colorIcons(variables.$black50);
      @include specifics.autoStrokeWidthIcons;

      padding: 0 variables.$base-factor * 3;
      width: variables.$base-factor * 4;
    }

    & > svg {
      transition: all 0.15s ease-out;
    }
  }

  &Open {
    .tbInputSelectArrowIndicator svg {
      transform: rotate(-180deg);
    }
  }
}

.tbInputSelectArrowIndicator {
  height: 100%;
  transition: background 0.15s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: all 0.35s ease-out;
  }

  &:hover {
    background-color: variables.$white101;
  }
}

.tbInputSelectHasError {
  .tbInputSelectControl {
    border-color: variables.$error;

    &:hover {
      border-color: variables.$error;
    }
  }
}
