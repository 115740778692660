@use "../../../styles/variables";
@use "../../../styles/textStyles";
@use "../../../styles/specifics";
@use "../../../styles/utils";

.tbInputWrapper {
  box-sizing: border-box;
  align-items: center;
  border: 1px solid variables.$white107;
  background-color: variables.$white90;
  border-radius: variables.$border-radius2;
  display: flex;
  height: variables.$input-height;
  justify-content: center;
  min-width: variables.$min-input-width;
  overflow: hidden;
  padding: 0 variables.$base-factor * 3;
  transition: border 0.35s ease-out;

  & > svg {
    @include specifics.colorIcons(variables.$black50, variables.$white90);

    margin-right: variables.$base-factor * 2;
    width: variables.$base-factor * 4;
    stroke-width: 1.5px;
    transition: all 0.35s ease-out;

    path {
      transition: all 0.35s ease-out;
    }
  }

  &Separator {
    background-color: variables.$white107;
    border-radius: variables.$border-radius3;
    height: 70%;
    margin-right: variables.$base-factor * 2;
    width: 1px;
    transition: background 0.35s ease-out;
  }

  &:focus-within {
    border: 1px solid variables.$purple100;
  }

  input,
  select {
    @include utils.allUnset;
    @include textStyles.body;

    display: flex;
    align-items: center;
    justify-content: center;
    color: variables.$black100;
    flex: 1;
    height: variables.$input-height;
    background-color: transparent !important;
    text-align: left;

    &::placeholder {
      color: variables.$black50;
    }
  }
}

.tbInputLabel {
  @include utils.allUnset;
  @include textStyles.inputLabel;

  color: variables.$black70;
  display: inline-flex;
  align-items: center;
  margin-bottom: variables.$base-factor;

  svg {
    margin-left: variables.$base-factor * 1;
    stroke-width: 2px;
    width: 15px;
  }
}

.tbInputError {
  @include utils.allUnset;
  @include textStyles.label;

  color: variables.$error;
  display: block;
  text-align: left;
}

.tbInputHasError {
  .tbInputWrapper {
    border: 1px solid variables.$error;
  }
}

.tbInputReadOnly {
  cursor: not-allowed;
  opacity: 0.4;

  input,
  select,
  textarea,
  button {
    cursor: not-allowed;
  }
}

.tbInputWithRemoveButton {
  padding-right: 0;
}

.tbInputSeparatorRemoveButton {
  margin-right: 0;
}

.tbInputRemoveButton {
  @include utils.allUnset;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.15s ease-out;
  height: 100%;
  width: variables.$base-factor * 10;

  &:hover {
    background-color: variables.$white101;
  }

  svg {
    @include specifics.colorIcons(variables.$black50, variables.$white90);

    margin: 0;
    padding: 0 variables.$base-factor;
  }
}
