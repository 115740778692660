@use "../../../styles/variables";

.tbForm {
  display: flex;
  flex-direction: column;
  gap: variables.$base-factor * 6;
  text-align: left;
  justify-content: center;
  margin-bottom: variables.$base-factor * 5;
  width: 100%;

  & > section {
    & > *:not(:last-child),
    fieldset:not(:last-child) {
      margin-bottom: variables.$base-factor * 3;
    }

    & > *:last-child,
    fieldset:last-child {
      margin-bottom: variables.$base-factor * 2;
    }
  }
}
